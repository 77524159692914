import Vue from 'vue'
import axios from 'axios'
// import VueAxios from 'vue-axios'
// import BootstrapVue from 'bootstrap-vue'
import 'bootstrap'
import AOS from 'aos'
import 'aos/dist/aos.css'
// import VueHorizontal from 'vue-horizontal';
import Vuetify from 'vuetify'
import App from './App.vue'
import router from './router/router'
import store from './store'
import vuetify from '../src/plugins/vuetify'
import i18n from '../src/plugins/i18n'
import '@mdi/font/css/materialdesignicons.css'
import VueSocialSharing from 'vue-social-sharing'


window.Vue = Vue
Vue.config.productionTip = false
Vue.prototype.$axios = axios


const token = localStorage.getItem('access_token');
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/v1'

axios.defaults.baseURL = 'https://crm.laprofechris.com/api/v1'
// axios.defaults.baseURL = 'https://crm.ipssoma.com/api/v1'

// axios.defaults.baseURL = 'http://crm_ipssoma.test/api/v1'
// axios.defaults.baseURL = 'https://crm.laprofechris.com/api/v1';
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';

// Vue.use(VueAxios, axios)
// Vue.use(BootstrapVue)
// Vue.use(VueHorizontal)
Vue.use(Vuetify)
Vue.use(VueSocialSharing)

// Vue.use(vueTimeago, {
//   name: 'Timeago',
//   locale: 'es',
//   locales: {
//     'zh-CN': require('date-fns/locale/zh_cn'),
//     ja: require('date-fns/locale/ja'),
//     'es': require('date-fns/locale/es')
//   }
// })



Vue.directive('loading-spinner', {
  bind: function (el) {
    const spinner = document.createElement('v-progress-circular');
    spinner.setAttribute('color', 'primary');
    spinner.setAttribute('indeterminate', 'true');
    spinner.setAttribute('size', '32');
    el.appendChild(spinner);

    const image = new Image();
    image.src = el.src;
    image.onload = function () {
      el.style.backgroundImage = `url(${el.src})`;
      el.removeChild(spinner);
    };
  },
});


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vuetify/dist/vuetify.min.css'
// import vueTimeago from 'vue-timeago'

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')

//App.vue - note that what is relevant for Google 
// is the 'name' meta tag, the 'description' and the robots meta tag
// using the 'keywords' meta tag won't help, as Google ignores it