import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '../store'

Vue.use(VueI18n);

const messages = {
  fr: {
    message: {
        title_1: 'Début',
        title_2: 'Cours',
        title_3: 'Testimonios',
        title_4: 'Contact',

        // Title presentacion
        title_presentation: 'Votre voyage multilingue commence ici',
        sub_title_presentation: 'Nous savons comment vous aider à avoir une conversation fluide dans une langue seconde',
        signUp: "S'inscrire",
        title_bilingue: 'Devenez bilingue avec la méthode la plus dynamique et la plus actuelle.',

        // BEST FEATURES
        best_title_1 : 'Professeurs spécialisés',
        best_title_2: 'Méthodologie pédagogique',
        best_title_3 : 'Assistance à vie',
        best_sub_title_1: 'Personnel enseignant expert et compétent dans la langue.',
        best_sub_title_2: "Méthodologie pédagogique ludique et efficace pour apprendre une deuxième langue en peu de temps.",
        best_sub_title_3: 'Découvrez notre méthodologie et notre enseignement dynamique de qualité avec des professeurs experts.',
        // Titlte metodolo
        methodology_title: 'Méthodologie ludique et efficace pour apprendre rapidement une deuxième langue',
        sub_methodology_title_1: 'Méthodologie efficace : Notre méthodologie garantit des résultats.',
        sub_methodology_title_2: "Apprenez vite : maîtrisez le français, l'anglais, l'espagnol, le portugais, l'italien ou l'allemand en un rien de temps.",
        sub_methodology_title_3:'Approche ludique : profitez pendant que vous apprenez.',
        apply_now: 'Appliquer maintenant',
        get_an_appointment: "Obtenez un rendez-vous aujourd'hui !",

        // Tool for teachers
        tool_for_teacher_1: 'Outil pour les enseignants et les étudiants',
        tool_for_teacher_2 : 'Enseignants experts',
        tool_for_teacher_3 : 'Environnement sûr',
        
        // Educational tour
        education_tour_title: 'NOS STATISTIQUES',
        education_tour:'Nous sommes fiers de partager notre visite éducative avec vous.',
        education_tour_student: 'Étudiants',
        education_tour_cour: 'Cours',
        education_tour_exam: 'Examens DELF/DALF réussis.',

        // Our courses
        our_course: 'Nos cours',

        // FOTTER
        register_today: "INSCRIVEZ-VOUS AUJOURD'HUI ET COMMENCEZ VOTRE FORMATION VIRTUELLE",
        footer_contact_information: 'Coordonnées',
        footer_address: 'Adresse: Vitry-Sur-Seine, 94400, France',
        footer_num_contact: 'Numéro de contact:',
        footer_email: 'E-mail:',
        footer_quick_links: 'Liens rapides',
        footer_cours: 'Cours',
        footer_master: 'Devenez un maître',
        footer_contacts: 'Contactez-nous',
        footer_quotation: 'Devis ou conseil',
        footer_all_rights_reserved: 'Tous droits réservés',
        footer_writer: 'Écrivez-nous maintenant',
        footer_subscribe: "S'abonner",
        
        // INFORMACION DE CURSOS
        cour_category: 'Catégorie',
         cour_purchase: 'Acheter',
        cours_information: 'Information',
        cour_information_module: 'Module',
        cour_information_1: 'A propos de ce cours',
        cour_information_2: 'Objectifs du programme',
        cour_information_3: 'A qui est-ce adressé ?',
        cour_information_4 : "Méthodologie d'étude",
        cour_information_plan: "Plan d'étude",
        cour_recomendation: 'Recommandations',

        // ACADEMIA PROFECHRIS
        welcome_to: 'Bienvenue chez ProfeChris, votre académie numérique',
        all_courses: 'Tous les cours',
        my_courses: 'Mes cours',
        advertisements: 'Annonces',
        events: "Événements",
        find_a_course: 'Trouver un cours',
        not_purchased_a_course: "Vous n'avez pas encore acheté de cours",
        select_a_course: 'Sélectionnez un cours',
        current_progress: 'Progrès en cours',
        there_are_this_course: "Il n'y a pas d'examens pour ce cours",
        exam: 'Examen',
        status_action: 'Statut/Action',
        passed_exam: 'Examen réussi',
        take_exam: 'Passer un examen',
        course: 'Cours',
        end_date: 'Date de fin',
        save: 'inscrire',
        cancel: 'Annuler',
        accept: 'Accepter',
        confirm: 'Confirmer',
        close: 'Fermer',
        list_exam: 'Liste des examens',
        you_do_not_have_certificates: "Vous n'avez pas encore de certificats",
        list_of_certificates: 'Liste des certificats',
        select_a_certificate: 'Sélectionnez un certificat',
        download: 'Décharge',
        error_certificcate: "Une erreur s'est produite lors du téléchargement du fichier",
        start_date: 'Date de début',
        no_resources_available: 'Aucune ressource disponible',
        exam_results: "Résultats d'examen",
        no_exman_available: 'Aucun examen disponible',
        passed_this_exam: 'Vous avez déjà réussi cet examen',
        exam_of_this_class: 'Examen de cette classe',
        there_are_no_external_links: "Il n'y a pas de liens externes pour ce cours",
        your_file_id_downloading: 'Votre fichier est en cours de téléchargement...',
        my_calendar: 'Mon calendrier',
        remaining_days: 'Jours restants',

        header_1: 'Accueil',
        header_2: 'Mes cours',
        header_3: 'Place de marché',
        header_4: 'Mes examens',
        header_5: 'Mes certificats',
        header_6: 'Mon calendrier',
        class: 'Des classes',
        edit_profile: 'Editer le profil',
        password: 'Mot de passe',
        change_password: 'Changer le mot de passe',
        name: 'nom',
        full_names: 'Noms complets',
        user: 'Utilisateur',
        email: 'E-mail',
        phone: 'Téléphone',
        type_document: 'Type de document',
        n_document: 'Non. du document',
        new_password: 'Nouveau mot de passe',
        current_password: 'mot de passe actuel',
        repeat_new_password: 'Répété le nouveau mot de passe',
        message_profile: 'Minimum 8 caractères, lettres majuscules et symboles',
        details: 'Détails',
        account: 'Compte',
        security: 'Sécurité',
        my_perfil: 'Mon profil',
        sign_off: 'Fermer la session',
        syllabus: 'Syllabus',
        exams: 'Examens',
        dynamics: 'Dynamique',
        flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Flag_of_France.svg/1024px-Flag_of_France.svg.png',
        remaining_days_of_courses: 'Jours restants bien sûr',
        external_link: 'Liens externes',
        resources: 'Ressources',
        have_you_lost_your_password: 'Avez-vous perdu votre mot de passe',
        log_in: 'Commencer la session',
        register: 'Enregistrement',
        message_login: "Nom d'utilisateur et/ou mot de passe incorrects, réessayez",
        date_of_birth: 'Date de naissance',
        send_email_password: "Nous vous enverrons un e-mail avec vos informations d'identification, entrez votre e-mail dans la case suivante",
        
    }
  },
  es: {
    message: {
        title_1: 'Inicio',
        title_2: 'Cursos',
        title_3: 'Testimonios',
        title_4: 'Contacto',
        title_presentation: 'Tu Viaje Multilingüe Comienza Aquí',
        sub_title_presentation: 'Sabemos cómo ayudarte a tener una conversación fluida en un segundo idioma',
        signUp: "Inscríbete ahora",
        title_bilingue: 'Conviértete en bilingüe con el método más dinámico y actual.',
        best_title_1: 'Profesores especialistas',
        best_title_2: 'Metodología de enseñanza',
        best_title_3: 'Soporte de por vida',
        best_sub_title_1: 'Plana docente experta y competente en el idioma.',
        best_sub_title_2: "Metodología de enseñanza lúdica y efectiva para aprender un segundo idioma en poco tiempo.",
        best_sub_title_3: 'Descubre nuestra metodología y enseñanza dinámica de alta calidad con profesores expertos.',
        methodology_title: 'Metodología lúdica y efectiva para aprender un segundo idioma rápidamente',
        sub_methodology_title_1: 'Metodología efectiva: Nuestra metodología garantiza resultados.',
        sub_methodology_title_2: 'Aprende rápido: Maneja francés, inglés, español, portugués, italiano o alemán en poco tiempo.',
        sub_methodology_title_3:'Enfoque lúdico: Disfruta mientras aprendes.',
        apply_now: 'Aplicar ahora',
        get_an_appointment: 'Obtén una cita hoy!',
        tool_for_teacher_1: 'Herramienta para profesores y alumnos',
        tool_for_teacher_2: 'Profesores Expertos',
        tool_for_teacher_3: 'Ambiente Seguro',
        education_tour_title: 'NUESTRAS ESTADÍSTICAS',
        education_tour: 'Estamos orgullosos de compartir contigo nuestro recorrido educacional.',
        education_tour_student: 'Estudiantes',
        education_tour_cour: 'Cursos',
        education_tour_exam: 'Exámenes DELF/DALF aprobados.',
        our_course: 'Nuestros cursos',
        register_today: 'INSCRÍBETE HOY MISMO E INICIA TU FORMACIÓN VIRTUAL',
        footer_contact_information: 'Información de contacto',
        footer_address: 'Dirección: Vitry-Sur-Seine, 94400, France',
        footer_num_contact: 'Número de contacto:',
        footer_email: 'Email:',
        footer_quick_links: 'Enlaces rápidos',
        footer_cours: 'Cursos',
        footer_master: 'Vuélvete un maestro',
        footer_contacts: 'Contáctanos',
        footer_quotation: 'Cotización o consultoría',
        footer_all_rights_reserved: 'Todos los derechos reservados',
        footer_writer: 'Escríbenos ahora',
        footer_subscribe: 'Suscríbete',
        cour_category: 'Categoría',
        cour_purchase: 'Comprar',
        cours_information: 'Información',
        cour_information_module: 'Módulo',
        cour_information_1: 'Acerca de este curso',
        cour_information_2: 'Objetivos del programa',
        cour_information_3: '¿A quién va dirigido?',
        cour_information_4: 'Metodología de estudio',
        cour_information_plan: 'Plan de estudio',
        cour_recomendation: 'Recomendaciónes',

        welcome_to: 'Bienvenido a ProfeChris, tu academia digital',
        all_courses: 'Todos los cursos',
        my_courses: 'Mis Cursos',
        advertisements: 'Anuncios',
        events: 'Eventos',
        find_a_course: 'Buscar un curso',
        not_purchased_a_course: 'Aún no ha adquirido un curso',
        select_a_course: 'Seleccione un curso',
        current_progress: 'Progreso actual',
        there_are_this_course: 'No hay exámenes para este curso',
        exam: 'Examen',
        status_action: 'Estado/Acción',
        passed_exam: 'Examen Aprobado',
        take_exam: 'Realizar examen',
        course: 'Curso',
        end_date: 'Fecha de finalización',
        save: 'Guardar',
        cancel: 'Cancelar',
        accept: 'Aceptar',
        confirm: 'Confirmar',
        close: 'Cerrar',
        list_exam: 'Liste des examens',
        you_do_not_have_certificates: 'Usted aún no tiene certificados',
        list_of_certificates: 'Listado de certificados',
        select_a_certificate: 'Seleccione un certificado',
        download: 'Descargar',
        error_certificcate: 'A ocurrido un error al descargar el archivo',
        start_date: 'Fecha de inicio',
        no_resources_available: 'Ningún recurso disponible',
        exam_results: 'Resultado de exámenes',
        no_exman_available: 'Ningún examen disponible',
        passed_this_exam: 'Usted ya aprobó este examen',
        exam_of_this_class: 'Examen de esta clase',
        there_are_no_external_links: 'No hay enlaces externos para esta clase',
        your_file_id_downloading: 'Su archivo se está descargando...',
        my_calendar: 'Mi Calendario',
        remaining_days: 'Días restantes',

        header_1: 'Inicio',
        header_2: 'Mis Cursos',
        header_3: 'MarketPlace',
        header_4: 'Mis exámenes',
        header_5: 'Mis certificados',
        header_6: 'Mi calendario',
        class: 'Clases',

        // EDIT OCCOUNT
        edit_profile: 'Editar perfil',
        password: 'Contraseña',
        change_password: 'Cambiar Contraseña',
        name: 'Nombre',
        full_names: 'Nombres completos',
        user: 'Usuario',
        email: 'Email',
        phone: 'Telefono',
        type_document: 'Tipo de documento',
        n_document: 'Nro. de documento',
        new_password: 'Nueva Contraseña',
        current_password: 'Contraseña actual',
        repeat_new_password: 'Repetir nueva contraseña',
        message_profile: 'Mínimo 8 caracteres de largo, mayúsculas y símbolos',
        details: 'Detalles',
        account: 'Cuenta',
        security: 'Seguridad',
        my_perfil: 'Mi perfil',
        sign_off: 'Cerar Sesión',
        syllabus: 'Temario',
        exams: 'Exámenes',
        dynamics: 'Dinamicas',
        flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Bandera_de_Espa%C3%B1a.svg/750px-Bandera_de_Espa%C3%B1a.svg.png?20230921142512',
        remaining_days_of_courses: 'Días restantes del curso',
        external_link: 'Enlaces externos',
        resources: 'Recursos',
        have_you_lost_your_password: 'Has perdido tu contraseña',
        log_in: 'Iniciar sesión',
        register: 'Registrarse',
        message_login: 'Usuario y/o contraseña incorrecta, vuelva a intentarlo',
        date_of_birth: 'Fecha de nacimiento',
        send_email_password: 'Enviaremos un correo con sus credenciales, ingrese su correo en la siguiente casilla',
    }
  }
};

const idiomaSeleccionado = store.state.useStoreLanguage.idiomaSeleccionado;

const i18n = new VueI18n({
  locale: idiomaSeleccionado == 1 ? 'fr' : 'es',
  fallbackLocale: 'fr',
  messages
});

export default i18n;